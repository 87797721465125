// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import gtag from 'src/analytics';

Rails.start()
Turbolinks.start()
ActiveStorage.start()


// require("packs/modernizr");
require("packs/select2");
require("packs/plugins/bootstrap-notify");
require("packs/vendor");
// require("packs/plugins");

require("packs/jquery.ripples");

require("packs/jquery.flipster.min");
require("packs/pricerange");
require("packs/ScrollToPlugin.min");

require("packs/pb.calendar");
require("packs/materialize");
require("packs/mosaic");
// require("packs/swiper");

// require("packs/tweenslide");
// require("packs/Draggable.min");
// require("packs/ThrowPropsPlugin.min");
require("packs/DrawSVGPlugin.min");
require("packs/AnticipateEase.min");
// require("packs/MorphSVGPlugin.min");

require("packs/GSDevTools.min");
require("packs/gratedeal");
require("packs/particles.min");
require("packs/carbon-components");
require("packs/anime.min");

// require("packs/TweenMax.min");
// require("packs/bootstrap-dropdownhover");
// require("packs/brn-bgcloud");
// require("packs/car-script");
// require("packs/changer");
// require("packs/greensock");
// require("packs/jquery.easeScroll");
// require("packs/jquery.jInvertScroll");
// require("packs/jquery.jInvertScroll.min");
// require("packs/jquery.nicescroll.min");
// require("packs/layerslider.kreaturamedia.jquery");
// require("packs/layerslider.transitions");
// require("packs/liquiddistortion");
// require("packs/PicCarousel");
// require("packs/PicCarousel.min");
// require("packs/pixi.min");
// require("packs/rescalendar.min");
// require("packs/smooth-scroll.polyfills.min");
require("packs/main");
