import $ from 'jquery';
import select2 from 'select2';
import 'select2/dist/css/select2.css';

/// if you don't have turnbolinks then use this:
//// document.addEventListener('DOMContentLoaded', () => {
window.addEventListener('turbolinks:load', () => {
  $('.select2.select-from').select2({
    tags: true,
    tokenSeparators: [',', ' '],
    placeholder: 'From Location',
    width: '85%',
    minimumInputLength: '3',
    multiple: false,
    tags: false,
    language: {
      inputTooShort: function(args) {
        return "Minimum 3 characters";
      },
    },
    ajax: {
      url: '/search_flights/flight_options',
      dataType: 'json',
      delay: 250,
      data: function (params) {
        var query = {
          search: params.term,
        }
        return query;
      },
      processResults: function (data) {
        return {
          results: data.options
        };
      }
    }
  });

  $('.select2.select-to').select2({
    tags: true,
    tokenSeparators: [',', ' '],
    placeholder: 'To Location',
    width: '85%',
    minimumInputLength: '3',
    multiple: false,
    tags: false,
    language: {
      inputTooShort: function(args) {
        return "Minimum 3 characters";
      },
    },
    ajax: {
      url: '/search_flights/flight_options',
      dataType: 'json',
      delay: 250,
      data: function (params) {
        var query = {
          search: params.term,
        }
        return query;
      },
      processResults: function (data) {
        return {
          results: data.options
        };
      }
    }
  });

  $('.select2.select-city-options').select2({
    tags: true,
    tokenSeparators: [',', ' '],
    placeholder: 'Search for City',
    width: '85%',
    multiple: false,
    tags: false,
    ajax: {
      url: '/search_flights/city_options',
      dataType: 'json',
      delay: 250,
      data: function (params) {
        var query = {
          search: params.term,
          country: $(this).attr('data-country'),
          from_city: $(this).attr('data-from-city')
        }
        return query;
      },
      processResults: function (data) {
        return {
          results: data.options
        };
      }
    }
  });

  $('.select2.select-country-options').select2({
    tags: true,
    tokenSeparators: [',', ' '],
    placeholder: 'Search for Country',
    width: '85%',
    multiple: false,
    tags: false,
    ajax: {
      url: '/search_flights/country_options',
      dataType: 'json',
      delay: 250,
      data: function (params) {
        var query = {
          search: params.term,
        }
        return query;
      },
      processResults: function (data) {
        return {
          results: data.options
        };
      }
    }
  });

  $('.select2.select-city-options').on('select2:select', function (e) {
    var data = e.params.data;
    if (window.location.href.indexOf('/to/') >= 0){
      window.location.href = window.location.href + '/' + data.id
    }
    else{
      window.location.href = window.location.href + '/' + data.id + '/to'
    }
  });

  $('.select2.select-country-options').on('select2:select', function (e) {
    var data = e.params.data;
    window.location.href = window.location.href + '/' + data.id
  });

  $(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
  });
})
